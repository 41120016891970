<template>

    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Input</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-clipboard mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Data Input</span>
            </div>
        </div> 

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" style="width:100%" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Form Data Input
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm"  class="needs-validation"  enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom01">Stasioning</label>
                                    <select class="form-control" @change="showKlasifikasi()" v-model="form.id_sta">
                                        <option value="">- Pilih Stasioning -</option>
                                        <option v-for="(item, index) in dataStasioning" :key="index" :value="item.id">{{ item.stasioning }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom02">Klasifikasi</label>
                                    <select class="form-control" @change="showSubKlasifikasi(1)" v-model="form.id_klasifikasi">
                                        <option value="">- Pilih Klasifikasi -</option>
                                        <option v-for="(item, index) in dataKlasifikasi" :key="index" :value="item.id">{{ item.klasifikasi }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom01">Sub Klasifikasi</label>
                                    <select class="form-control" @change="showItemKlasifikasi(1)" v-model="form.id_sub">
                                        <option value="">- Pilih Sub Klasifikasi -</option>
                                        <option v-for="(item, index) in dataSubKlasifikasi" :key="index" :value="item.id">{{ item.sub_klasifikasi }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom02">Item Klasifikasi</label>
                                    <select class="form-control" v-model="form.id_item">
                                        <option value="">- Pilih Item Klasifikasi -</option>
                                        <option v-for="(item, index) in dataItemKlasifikasi" :key="index" :value="item.id">{{ item.item_klasifikasi }}</option>
                                    </select>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <label>MC 0</label>
                                <input type="text" v-model="form.mc_0" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Shop Drawing</label>
                                <input type="text" v-model="form.shop_drawing" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Perhitungan</label>
                                <input type="text" v-model="form.perhitungan" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Justifikasi Teknis</label>
                                <input type="text" v-model="form.justifikasi_teknik" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Administrasi Surat</label>
                                <input type="text" v-model="form.administrasi_surat" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom01">Foto Pelaksanaan</label>
                                    <input ref="fileFoto" @change="uploadFoto" type="file" accept="image/x-png,image/gif,image/jpeg" value="" class="form-control" placeholder="">
                                    <a v-if="foto_show" :href="$apiconfig + 'uploads/foto/' + form.foto_pelaksanaan" target="_blank" style="cursor:pointer" ><small class="text-primary">Lihat Lampiran Sebelumnya</small></a>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="validationCustom02">Laporan Mingguan</label>
                                    <input ref="fileInput" @change="uploadFile" type="file" accept="application/pdf" value="" class="form-control" placeholder="">
                                    <a v-if="laporan_show" :href="$apiconfig + 'uploads/laporan/' + form.laporan_mingguan" target="_blank" style="cursor:pointer" ><small class="text-primary">Lihat Lampiran Sebelumnya</small></a>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <!-- <div class="card-header"> -->
                                <div class="form-row m-2 mt-3">
                                    <div class="col-md-3">
                                        <select class="form-control" @change="filterKlasifikasi()" v-model="filter.f_sta">
                                            <option value="">- Pilih Stasioning -</option>
                                            <option v-for="(item, index) in listStasioning" :key="index" :value="item.id">{{ item.stasioning }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <select class="form-control" @change="filterSubKlasifikasi()" v-model="filter.f_klas">
                                            <option value="">- Pilih Klasifikasi -</option>
                                            <option v-for="(item, index) in listKlasifikasi" :key="index" :value="item.id">{{ item.klasifikasi }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <select class="form-control" @change="filterItemKlasifikasi()" v-model="filter.f_sub">
                                            <option value="">- Pilih Sub Klasifikasi -</option>
                                            <option v-for="(item, index) in listSubKlasifikasi" :key="index" :value="item.id">{{ item.sub_klasifikasi }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <select class="form-control" v-model="filter.f_item">
                                            <option value="">- Pilih Item Klasifikasi -</option>
                                            <option v-for="(item, index) in listItemKlasifikasi" :key="index" :value="item.id">{{ item.item_klasifikasi }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-10 mt-3">
                                        <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Data...">
                                    </div>
                                    <div class="col-md-2 mt-3">
                                        <button class="col-12 btn btn-primary" @click="show_modal()">
                                            <i class="fe fe-plus mr-2"></i>Tambah Data
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Data...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary ml-2" @click="show_modal()">
                                        <div class="d-flex align-items-center">
                                            <i class="fe fe-plus mr-2"></i>Tambah Data
                                        </div>
                                    </button>
                                </div> -->
                            <!-- </div> -->
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>Klasifikasi</th>
                                            <th>MC 0</th>
                                            <th>Shop Drawing</th>
                                            <th>Perhitungan</th>
                                            <th>Justifikasi Teknis</th>
                                            <th>Administrasi Surat</th>
                                            <th>Dokumen</th>
                                            <th>Tanggal</th>
                                            <th style="width:10%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="pagination.totalPages == 0">
                                        <tr>
                                            <td colspan="10">
                                                <div class="alert alert-danger text-center" role="alert">
                                                    Data Tidak Tersedia
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in dataInput" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.klasifikasi }} &nbsp; | &nbsp; {{ item.sub_klasifikasi }} &nbsp; | &nbsp; {{ item.item_klasifikasi }}</td>
                                            <td>{{ item.mc_0 }}</td>
                                            <td>{{ item.shop_drawing }}</td>
                                            <td>{{ item.perhitungan }}</td>
                                            <td>{{ item.justifikasi_teknik }}</td>
                                            <td>{{ item.administrasi_surat }}</td>
                                            <td>
                                                <a v-if="item.foto_pelaksanaan" :href="$apiconfig + 'uploads/foto/' + item.foto_pelaksanaan" target="_blank" style="cursor:pointer" class="btn btn-secondary btn-sm mr-2"><i class="fe fe-file"></i></a>
                                                <a v-if="item.laporan_mingguan" :href="$apiconfig + 'uploads/laporan/' + item.laporan_mingguan" target="_blank" style="cursor:pointer" class="btn btn-secondary btn-sm mr-2"><i class="fe fe-file"></i></a>
                                                <a v-if="!item.laporan_mingguan && !item.foto_pelaksanaan">-</a>
                                            </td>
                                            <td>{{ item.created_at }}</td>
                                            <td class="text-center">
                                                <button @click.prevent="editForm(item.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                <button @click.prevent="postDelete(item.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>

</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'DTJMT',
            titleTemplate: '%s - Data Input'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataInput: [],
                dataStasioning: [],
                dataKlasifikasi: [],
                dataSubKlasifikasi: [],
                dataItemKlasifikasi: [],
                listStasioning: [],
                listKlasifikasi: [],
                listSubKlasifikasi: [],
                listItemKlasifikasi: [],
                fetching: true,
                foto_show: false,
                laporan_show: false,
                filter: {
                    f_sta: '',
                    f_klas: '',
                    f_sub: '',
                    f_item: '',
                },
                form: {
                    id: '',
                    id_sta: '',
                    id_item: '',
                    id_klasifikasi: '',
                    id_sub: '',
                    mc_0: '',
                    shop_drawing: '',
                    foto_pelaksanaan: '',
                    perhitungan: '',
                    justifikasi_teknik: '',
                    administrasi_surat: '',
                    laporan_mingguan: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
            this.getStasioning();
            this.getKlasifikasi();
            this.showSubKlasifikasi(1);
            this.showItemKlasifikasi(1);
        },
        components: {
            vPagination
        },
        methods: {
            show_modal() {
                this.showSelectYear = true;
                this.form.id= '',
                this.form.id_sta= '',
                this.form.id_item= '',
                this.form.id_klasifikasi= '',
                this.form.id_sub= '',
                this.form.mc_0= '',
                this.form.shop_drawing= '',
                this.form.foto_pelaksanaan= '',
                this.form.perhitungan= '',
                this.form.justifikasi_teknik= '',
                this.form.administrasi_surat= '',
                this.form.laporan_mingguan= '',
                this.foto_show= false,
                this.laporan_show= false,
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data data periode
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/input', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        f_sta: this.filter.f_sta,
                        f_klas: this.filter.f_klas,
                        f_sub: this.filter.f_sub,
                        f_item: this.filter.f_item,
                    }
                })
                    .then((response) => {
                        this.dataInput = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getStasioning() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/stasioning', {
                    params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataStasioning = response.data.data;
                        this.listStasioning = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getKlasifikasi() {
                this.$http.get(this.baseUrl + 'admin/master/klasifikasi', {
                    params: {
                        page : 1,
                    }
                })
                    .then((response) => {
                        this.dataKlasifikasi = response.data.data;
                        this.listKlasifikasi = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            // save periode
            submitForm() {
                const formData = new FormData();
                formData.append('id', this.form.id);
                formData.append('id_sta', this.form.id_sta);
                formData.append('id_item', this.form.id_item);
                formData.append('id_klasifikasi', this.form.id_klasifikasi);
                formData.append('id_sub', this.form.id_sub);
                formData.append('mc_0', this.form.mc_0);
                formData.append('shop_drawing', this.form.shop_drawing);
                formData.append('foto_pelaksanaan', this.form.foto_pelaksanaan);
                formData.append('perhitungan', this.form.perhitungan);
                formData.append('justifikasi_teknik', this.form.justifikasi_teknik);
                formData.append('administrasi_surat', this.form.administrasi_surat);
                formData.append('laporan_mingguan', this.form.laporan_mingguan);

                this.$http.post(this.baseUrl + 'admin/input/save', formData)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$modal.hide('my-modal')
                        this.getData()
                        this.form.sta_awal = ''
                        this.form.sta_akhir = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/input/detail?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.id_sta = response.data.data[0].id_sta;
                        this.form.id_item = response.data.data[0].id_item;
                        this.form.id_klasifikasi = response.data.data[0].id_klasifikasi;
                        this.form.id_sub = response.data.data[0].id_sub;
                        this.form.mc_0 = response.data.data[0].mc_0;
                        this.form.shop_drawing = response.data.data[0].shop_drawing;
                        this.form.foto_pelaksanaan = response.data.data[0].foto_pelaksanaan;
                        this.form.perhitungan = response.data.data[0].perhitungan;
                        this.form.justifikasi_teknik = response.data.data[0].justifikasi_teknik;
                        this.form.administrasi_surat = response.data.data[0].administrasi_surat;
                        this.form.laporan_mingguan = response.data.data[0].laporan_mingguan;
                        this.foto_show = response.data.data[0].laporan_mingguan;
                        this.laporan_show = response.data.data[0].laporan_mingguan;
                        this.showSubKlasifikasi(0);
                        this.showItemKlasifikasi(0);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/input/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data berhasil dihapus.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Data sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            uploadFile() {
                const file = this.$refs.fileInput.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 10) {
                    alert("File yang di upload tidak boleh lebih 10 MB");
                } else {
                   this.form.laporan_mingguan = this.$refs.fileInput.files[0];
                }
            },
            uploadFoto() {
                let file = this.$refs.fileFoto.files[0]; 
                console.log(file);
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.foto_pelaksanaan = this.$refs.fileFoto.files[0];
                }
            },
            showKlasifikasi() {
                this.getKlasifikasi();
            },
            showSubKlasifikasi(key) {
                this.$http.get(this.baseUrl + 'admin/master/sub_klasifikasi/klasifikasi', {
                    params: {
                        page : 1,
                        id_klasifikasi: this.form.id_klasifikasi
                    }
                })
                    .then((response) => {
                        this.dataSubKlasifikasi = response.data.data;
                        if(key == 1){
                            this.form.id_sub = '';
                            this.form.id_item = '';
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            showItemKlasifikasi(key) {
                this.$http.get(this.baseUrl + 'admin/master/item_klasifikasi/klasifikasi', {
                    params: {
                        page : 1,
                        id_sub_klasifikasi: this.form.id_sub
                    }
                })
                    .then((response) => {
                        this.dataItemKlasifikasi = response.data.data;
                        if(key == 1){
                            this.form.id_item = '';
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            filterKlasifikasi() {
                this.getData();
            },
            filterSubKlasifikasi() {
                this.$http.get(this.baseUrl + 'admin/master/sub_klasifikasi/klasifikasi', {
                    params: {
                        page : 1,
                        id_klasifikasi: this.filter.f_klas
                    }
                })
                    .then((response) => {
                        this.listSubKlasifikasi = response.data.data;
                        this.filter.f_sub = '';
                        this.filter.f_item = '';
                        this.getData();
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            filterItemKlasifikasi() {
                this.$http.get(this.baseUrl + 'admin/master/item_klasifikasi/klasifikasi', {
                    params: {
                        page : 1,
                        id_sub_klasifikasi: this.filter.f_sub
                    }
                })
                    .then((response) => {
                        this.listItemKlasifikasi = response.data.data;
                        this.filter.f_item = '';
                        this.getData();
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>